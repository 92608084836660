<template>
  <section class="p-1 dashboard-ctn">
    <iframe v-if="url"
      :src="url"
      style="display: flex;"
      frameborder="0"
      width="100%"
      height="100%"
      allowtransparency
      @load="hideLoading"
    >
    </iframe>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  components: {},
  data() {
    return {
      baseService: new BaseServices(this),
      userData: null,
      roleKey: {
        seller: 'shipper',
        ecommerce: 'organization',
        marketplace: 'organization'
      },
      roleCode: Object.freeze({
        admin: '16113811-706d-460e-8056-0fc6eea71702',
        superadmin: '16113811-706d-460e-8056-0fc6eea71702',
        seller: 'a97d1553-d882-47b7-8f14-b03dac40097d',
        ecommerce: 'a97d1553-d882-47b7-8f14-b03dac40097d',
        marketplace: 'c8a75ad9-05b5-41d4-a8d0-aa75dd319add'
      }),
      url: ''
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (!this.userData) this.userData = this.$session.get('cas_user')
    },
    userData () {
      if (!this.userData) return
      this.setUrlConfig()
    }
  },
  mounted () {
    this.$loadingAlert({text: 'Obteniendo datos, por favor espere...', color: 'primary'})
    this.userData = this.$session.get('cas_user')
  },
  methods: {
    send (form) {
    },
    getJSON () {
      this.$root.$data.db.collection(this.collection).doc(this.doc).onSnapshot(resp => {
        this.json = JSON.stringify(resp.data(), undefined, 4)
      })
    },
    clickAbility () {
      // this.$store.dispatch('fetchPermissions', {permissions: this.$session.get('cas_user').permissions})
    }
  }
}
</script>

<style lang="scss">
.dashboard-ctn {
  height: 100vh;
}
</style>